import { MenuLinkButton } from "@curaleaf-international/components";
import { Inventory } from "@mui/icons-material";
import AppsIcon from "@mui/icons-material/Apps";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import FlightIcon from "@mui/icons-material/Flight";
import GroupsIcon from "@mui/icons-material/Groups";
import Groups3Icon from "@mui/icons-material/Groups3";
import HandymanIcon from "@mui/icons-material/Handyman";
import HelpIcon from "@mui/icons-material/Help";
import MedicationIcon from "@mui/icons-material/Medication";
import PaymentsIcon from "@mui/icons-material/Payments";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SummarizeIcon from "@mui/icons-material/Summarize";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import { useContext } from "react";

import { AuthContext } from "src/AuthContext";
import RequiresRoles from "src/components/RequiresRoles";
import { Role } from "src/models";

const DRAWER_WIDTH = 240;

interface IProps {
  closeDrawer: () => void;
  drawerOpen: boolean;
}

const LeftDrawer = ({ closeDrawer, drawerOpen }: IProps) => {
  const { member } = useContext(AuthContext);
  const menu = (
    <>
      <Toolbar />
      <List>
        {member ? (
          <>
            <MenuLinkButton
              Icon={<CalendarMonthIcon />}
              label="Appointments"
              onClick={closeDrawer}
              to="/appointments/"
            />
            <RequiresRoles
              roles={[
                Role.DEVELOPER,
                Role.PHARMACIST,
                Role.GENERAL_PRACTITIONER,
                Role.CONSULTANT,
                Role.MEDICAL_SECRETARY,
                Role.MEDICAL_SECRETARY_LEAD,
              ]}
            >
              <MenuLinkButton
                Icon={<RemoveRedEyeIcon />}
                label="Clinical Reviews"
                onClick={closeDrawer}
                to="/clinical-reviews/"
              />
            </RequiresRoles>
            <RequiresRoles
              roles={[
                Role.DEVELOPER,
                Role.FINANCE,
                Role.CUSTOMER_SERVICE_LEAD,
                Role.MEDICAL_SECRETARY_LEAD,
              ]}
            >
              <MenuLinkButton
                Icon={<PaymentsIcon />}
                label="Appointment Payments"
                onClick={closeDrawer}
                to="/appointment-payments/"
              />
            </RequiresRoles>
            <RequiresRoles
              roles={[
                Role.CUSTOMER_SERVICE,
                Role.CUSTOMER_SERVICE_LEAD,
                Role.PHARMACIST,
                Role.PHARMACIST_TECHNICIAN,
                Role.STAFF_MANAGER,
                Role.DEVELOPER,
                Role.MEDICAL_SECRETARY,
                Role.MEDICAL_SECRETARY_LEAD,
              ]}
            >
              <MenuLinkButton
                Icon={<PeopleAltIcon />}
                label="Patients"
                onClick={closeDrawer}
                to="/patients/"
              />
            </RequiresRoles>
            <RequiresRoles
              roles={[
                Role.PHARMACIST,
                Role.PHARMACIST_TECHNICIAN,
                Role.MEDICAL_SECRETARY,
                Role.MEDICAL_SECRETARY_LEAD,
                Role.DEVELOPER,
              ]}
            >
              <MenuLinkButton
                Icon={<MedicationIcon />}
                label="Prescribers"
                onClick={closeDrawer}
                to="/prescribers/"
              />
            </RequiresRoles>
            <RequiresRoles
              roles={[
                Role.PHARMACIST,
                Role.PHARMACIST_TECHNICIAN,
                Role.STAFF_MANAGER,
                Role.DEVELOPER,
                Role.MEDICAL_SECRETARY,
                Role.MEDICAL_SECRETARY_LEAD,
              ]}
            >
              <MenuLinkButton
                Icon={<Groups3Icon />}
                label="Clinicians"
                onClick={closeDrawer}
                to="/clinicians/"
              />
            </RequiresRoles>
            <RequiresRoles
              roles={[
                Role.REPORTER,
                Role.GENERAL_PRACTITIONER,
                Role.PHARMACIST,
                Role.PHARMACIST_TECHNICIAN,
                Role.STAFF_MANAGER,
                Role.DEVELOPER,
                Role.MEDICAL_SECRETARY,
                Role.CUSTOMER_SERVICE,
                Role.CUSTOMER_SERVICE_LEAD,
              ]}
            >
              <MenuLinkButton
                Icon={<VaccinesIcon />}
                label="Formulary"
                onClick={closeDrawer}
                to="/formulas/"
              />
            </RequiresRoles>
            <RequiresRoles
              roles={[
                Role.REPORTER,
                Role.GENERAL_PRACTITIONER,
                Role.PHARMACIST,
                Role.PHARMACIST_TECHNICIAN,
                Role.STAFF_MANAGER,
                Role.DEVELOPER,
                Role.MEDICAL_SECRETARY,
                Role.CUSTOMER_SERVICE,
                Role.CUSTOMER_SERVICE_LEAD,
              ]}
            >
              <MenuLinkButton
                Icon={<AppsIcon />}
                label="Speciality Groups"
                onClick={closeDrawer}
                to="/speciality-groups/"
              />
            </RequiresRoles>
            <MenuLinkButton
              Icon={<WorkspacesIcon />}
              label="Clinical Alternative Groups"
              onClick={closeDrawer}
              to="/product-groups/"
            />
            <MenuLinkButton
              Icon={<Inventory />}
              label="Out of Stock Listings"
              onClick={closeDrawer}
              to="/out-of-stock-listings/"
            />
            <RequiresRoles
              roles={[
                Role.PHARMACIST,
                Role.PHARMACIST_TECHNICIAN,
                Role.STAFF_MANAGER,
                Role.DEVELOPER,
                Role.MEDICAL_SECRETARY,
                Role.MEDICAL_SECRETARY_LEAD,
                Role.CUSTOMER_SERVICE,
                Role.CUSTOMER_SERVICE_LEAD,
                Role.REPORTER,
              ]}
            >
              <MenuLinkButton
                Icon={<GroupsIcon />}
                label="Staff"
                onClick={closeDrawer}
                to="/staff/"
              />
            </RequiresRoles>
            <RequiresRoles
              roles={[
                Role.PHARMACIST,
                Role.PHARMACIST_TECHNICIAN,
                Role.DEVELOPER,
                Role.CUSTOMER_SERVICE,
                Role.CUSTOMER_SERVICE_LEAD,
                Role.MEDICAL_SECRETARY,
                Role.MEDICAL_SECRETARY_LEAD,
              ]}
            >
              <MenuLinkButton
                Icon={<ReceiptLongIcon />}
                label="FP10 Log"
                onClick={closeDrawer}
                to="/fp10s/"
              />
            </RequiresRoles>
            <RequiresRoles
              roles={[
                Role.PHARMACIST,
                Role.PHARMACIST_TECHNICIAN,
                Role.DEVELOPER,
                Role.CUSTOMER_SERVICE,
                Role.CUSTOMER_SERVICE_LEAD,
                Role.MEDICAL_SECRETARY,
                Role.MEDICAL_SECRETARY_LEAD,
                Role.CONSULTANT,
                Role.GENERAL_PRACTITIONER,
              ]}
            >
              <MenuLinkButton
                Icon={<FlightIcon />}
                label="Travel Letters"
                onClick={closeDrawer}
                to="/travel-letters/"
              />
            </RequiresRoles>
            <RequiresRoles
              roles={[
                Role.PHARMACIST,
                Role.CUSTOMER_SERVICE_LEAD,
                Role.DEVELOPER,
                Role.MEDICAL_SECRETARY,
                Role.MEDICAL_SECRETARY_LEAD,
                Role.REPORTER,
              ]}
            >
              <MenuLinkButton
                Icon={<SummarizeIcon />}
                label="Reports"
                onClick={closeDrawer}
                to="/reports/"
              />
            </RequiresRoles>
            <RequiresRoles
              roles={[
                Role.PHARMACIST,
                Role.CUSTOMER_SERVICE_LEAD,
                Role.DEVELOPER,
                Role.MEDICAL_SECRETARY,
                Role.MEDICAL_SECRETARY_LEAD,
                Role.REPORTER,
              ]}
            >
              <MenuLinkButton
                Icon={<CreditCardIcon />}
                label="Subscriptions"
                onClick={closeDrawer}
                to="/subscriptions/"
              />
            </RequiresRoles>
            <RequiresRoles
              roles={[
                Role.CUSTOMER_SERVICE_LEAD,
                Role.DEVELOPER,
                Role.PHARMACIST,
                Role.MEDICAL_SECRETARY,
                Role.MEDICAL_SECRETARY_LEAD,
              ]}
            >
              <MenuLinkButton
                Icon={<HandymanIcon />}
                label="Tools"
                onClick={closeDrawer}
                to="/tools/"
              />
            </RequiresRoles>
            <MenuLinkButton
              Icon={<HelpIcon />}
              label="Help"
              onClick={closeDrawer}
              to="/help/"
            />
          </>
        ) : null}
      </List>
    </>
  );

  return (
    <>
      <Drawer
        ModalProps={{
          keepMounted: true,
        }}
        onClose={closeDrawer}
        open={drawerOpen}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
        }}
        variant="temporary"
      >
        {menu}
      </Drawer>
      <Drawer
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
          width: DRAWER_WIDTH,
          flexShrink: 0,
        }}
        open
        variant="permanent"
      >
        {menu}
      </Drawer>
    </>
  );
};

export default LeftDrawer;
